import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import Vuex from 'vuex';
import axios from 'axios';

const API_URL = 'https://tattext.ru';


axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
const app = createApp(App);
const store = new Vuex.Store({
    state: {
        results: {},
        loading: false,
        id: null,
    },
    actions: {
        async checkText({ commit }, text) {
            commit('SET_LOADING', true);
            try {
                const response = await axios.post(`${API_URL}/check/`, text);
                commit('SET_ID', response.data.id);
            } catch (err) {
                console.error(err);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async checkFile({ commit }, file) {
            const formData = new FormData();
            formData.append('file', file);
            commit('SET_LOADING', true);
            try {
                const response = await axios.post(`${API_URL}/check/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                commit('SET_RESULTS', response.data);
            } catch (err) {
                console.error(err);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async updateResults({ commit }, id) {
            commit('SET_LOADING', true);
            try {
                const response = await axios.get(`${API_URL}/results/${id}/`);
                commit('SET_RESULTS', response.data);
            } catch (err) {
                console.error(err);
            } finally {
                commit('SET_LOADING', false);
            }
        },
    },
    mutations: {
        SET_RESULTS(state, results) {
            state.results = results;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ID(state, id) {
            state.id = id;
        },
    },
    getters: {
        results: (state) => state.results,
        loading: (state) => state.loading,
        id: (state) => state.id,
        percent: (state) => {
            if (state.results.analysed) {
                let analysed = Math.round((state.results.analysed / state.results.total) * 100);
                return `Проверка текста: ${analysed}%.`;
            }
        },
    },
});


import MainComponent from './components/Main';
import ResultsComponent from './components/Results';
import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    { path: '/', component: MainComponent },
    { path: '/:id/results', component:  ResultsComponent, name: 'results' },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

app.use(store);
app.use(ElementPlus);
app.use(router);
app.mount('#app');
